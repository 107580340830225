import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Header, Button, Dimmer, Loader, Form, Icon, Modal, Label, Dropdown, Select, Input, Popup, Confirm } from 'semantic-ui-react';
import InfiniteScroll from "react-infinite-scroll-component";

import { registerUpdateBU, getBusinessUserList, emptyBUserList, searchList, handleRemove } from '../../actions/userAction';
import { getBranchListForDropdown } from '../../actions/branchAction';
import Message from '../stateless/Message';

const initialDto = {
  id: '',
  branches: [],
  email: '',
  muted: false,
  phone: '',
  name: '',
  roles: []
};

class BusinessUserList extends Component {
  state = {
    businessUserList: [],
    hasMore: true,
    branchId: '',
    role: '',
    query: '',
    branchListOptions: [],
    inputValue: '',
    phone: '',
    whatsapp_phone: '',
    currentUser: initialDto,
    confirmOpen: false,
    isDuplicated: false,
    //for Message
    positive: true,
    hidden: true,
    messageTitle: '',
    messageBody: '',
    height: 512
  };

  componentDidMount() {
    this.updateWindowDimensions();
    this.props.getBranchListForDropdown('/api/v1/branches');
    const searchParams = new URLSearchParams(this.props.location.search);
    if (searchParams.size > 0) {
      const query = searchParams.get('query');
      const phone = searchParams.get('phone');
      const whatsapp_phone = searchParams.get('whatsapp_phone');
      this.setState({ query, phone, whatsapp_phone });
      this.searchBusinessUsersByParams(searchParams.toString());
    } else this.props.getBusinessUserList('/api/v1/business-users');
  }

  updateWindowDimensions() {
    this.setState({ height: window.outerHeight - 230 });
  }

  componentDidUpdate(prevProps) {
    //console.log(prevProps);
    if (prevProps.businessUserList !== this.props.businessUserList) {
      const { businessUserList } = this.props;
      this.setState({ businessUserList });
    }

    if (prevProps.branchList !== this.props.branchList) {
      const { branchList } = this.props;
      var branchListOptions = [];
      branchList.forEach(element => {
        var obj = {
          key: element.id,
          value: element.id,
          text: element.name
        }
        branchListOptions.push(obj);
      });
      this.setState({ branchListOptions });
    }
  }

  componentWillUnmount() {
    this.props.emptyBUserList();
  }

  onChange = (e) => {
    const { value, name } = e.target;
    const { currentUser } = this.state;
    this.setState({
      [name]: value
    }, () => {
      if (name === 'phone' || name === 'whatsapp_phone' || name === 'query') {
        const searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set([name], value);
        this.props.history.replace({ search: searchParams.toString() });
        this.searchBusinessUsersByParams(searchParams.toString());
      } else {
        if (name === "userPhone") {
          this.setState({
            currentUser: {...currentUser, phone: value}
          })
        } else {
          this.setState({
            currentUser: {
              ...currentUser,
              [name]: value
            }
          });
        }
      }
    });
  }

  searchBusinessUsersByParams = (searchParams) => {
    this.props.searchList(`/api/v1/business-users/1/find/BUser?limit=20&${searchParams}`)
      .then(res => {
        if (res.status === 200) {
          this.setState({ hasMore: res.data.data.length < 20 ? false : true });
        }
      });
  }

  onSelectChange = (e, data) => {
    const value = data.type === "checkbox" ? data.checked : data.value;
    const { name, options } = data;
    let { currentUser, branchId } = this.state;

    if (name === 'branchId') {
      const chosenBranch = options.find(x => x.value === value);
      if (chosenBranch) {
        this.setState({
          currentUser: {
            ...currentUser,
            branches: [...currentUser.branches, { id: chosenBranch.value, name: chosenBranch.text, role: 'no role' }]
          }
        });
      } else {
        const list = [...currentUser.branches].filter(x => x.id !== branchId);
        this.setState({
          currentUser: {
            ...currentUser,
            branches: list
          }
        });
      }
    }
    else if (name === 'role') {
      const branchList = [...currentUser.branches];
      const index = branchList.length - 1;
      let lastBranch = branchList[index];
      const foundBranch = branchList.find((x) => x.name === lastBranch.name && x.role === value);
      if (!foundBranch) {
        lastBranch.role = value;
        this.setState({
          currentUser: {
            ...currentUser,
            branches: [...currentUser.branches.slice(0, index), lastBranch, ...currentUser.branches.slice(index + 1)],
            roles: [...currentUser.roles, value]
          }
        }, () => {
          this.setState({
            branchId: '',
            role: '',
            isDuplicated: false
          })
        })
      } else {
        this.setState({
          currentUser: {
            ...currentUser,
            branches: branchList.slice(0, -1)
          },
          branchId: '',
          role: '',
          isDuplicated: true
        })
        setTimeout(() => {
          this.setState({ isDuplicated: false });
        }, 5000);
      }
    }
    this.setState({
      [name]: value
    });
  }

  onSearchChange = (e, value) => {
    this.props.getBranchListForDropdown('/api/v1/branches?query=' + value.searchQuery);
  }

  //for toggling the Modal
  handleModalToggle = (id) => {
    if (id !== '') {
      const { businessUserList } = this.state;
      const foundBU = businessUserList.find(x => x.id === id);
      this.setState({
        currentUser: foundBU
      }, () => {
        this.setState({ modalOpen: !this.state.modalOpen });
      })
    }
    else {
      this.setState({
        currentUser: initialDto
      }, () => {
        this.setState({ modalOpen: !this.state.modalOpen });
      })
    }
  }

  //for adding the branch to state list
  deleteBranch(branchId) {
    const { currentUser } = this.state;
    this.setState({
      currentUser: {
        ...currentUser,
        branches: [...currentUser.branches].filter(x => x.id !== branchId)
      }
    })
  }

  //resgisters/updates the business-user
  registerUpdateUser = () => {
    const { currentUser } = this.state;
    const URL = currentUser.id ? '/api/v1/business-users/' + currentUser.id : '/api/v1/business-users/user/create';

    if (currentUser.branches.length <= 0 || !currentUser.phone)
      return;

    this.props.registerUpdateBU(URL, currentUser, currentUser.id)
      .then(res => {
        if (res) {
          if (res.status === 200) {
            this.setState({
              currentUser: initialDto,
              modalOpen: false,
              positive: true,
              hidden: false,
              messageTitle: 'Registered.',
              messageBody: 'The new user was successfully registered.'
            })

            setTimeout(() => {
              this.setState({ hidden: true })
            }, 4000)
          }
        }
      });
  }

  //load more branches
  fetchMoreData = () => {
    this.props.getBusinessUserList('/api/v1/business-users?limit=20&before=' + this.props.lastItemId)
      .then(res => {
        if (res) {
          if (res.status === 200) {
            var arrLength = res.data.data.length;
            if (arrLength === 0) {
              this.setState({ hasMore: false });
              return;
            }
          }
        }
      });
  };

  // calls confirmation window for deleting the bUser
  handelConfirmation = (businessUserId) => {
    const { businessUserList } = this.state;
    const foundBU = businessUserList.find(x => x.id === businessUserId);
    this.setState({ confirmOpen: !this.state.confirmOpen, currentUser: foundBU });
  }

  //removes the bUser
  deleteBusinessUser(businessUserId) {
    this.props.handleRemove('/api/v1/business-users/' + businessUserId, businessUserId)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            positive: true,
            hidden: false,
            messageTitle: 'Deleted.',
            messageBody: 'The user was successfully deleted.'
          });
        }
      }).catch((error) => {
        if (error.data) {
          this.setState({
            positive: false,
            hidden: false,
            messageTitle: 'Error.',
            messageBody: error.data.message ? error.data.message : 'Server side error'
          });
        }
      }).finally(() => {
        this.setState({
          currentUser: initialDto,
          confirmOpen: false
        });
        setTimeout(() => {
          this.setState({ hidden: true })
        }, 4000)
      });
  }

  render() {
    const { businessUserList, branchListOptions, modalOpen, confirmOpen, query,
      currentUser, branchId, role, phone, whatsapp_phone, height, isDuplicated } = this.state;
    const roleOptions = [
      { key: 0, value: 'administrator', text: 'administrator' },
      { key: 1, value: 'cashier', text: 'cashier' },
      { key: 2, value: 'employer', text: 'employer' },
      { key: 3, value: 'analytic', text: 'analytic' },
      { key: 4, value: 'qr', text: 'qr' },
      { key: 5, value: 'integration', text: 'integration' },
    ];

    return (
      <div style={{ paddingLeft: '5px', height: '100%' }}>
        <Grid style={{ margin: '0' }}>
          <Grid.Row columns='equal'>
            <Grid.Column></Grid.Column>
            <Grid.Column textAlign='center'>
              <Header as='h3'>Business User List</Header>
            </Grid.Column>
            <Grid.Column>
              <Button color='teal' floated='right' compact
                onClick={this.handleModalToggle.bind(this, '')}>
                <Icon name='add' />
                Register a new user
              </Button>
            </Grid.Column>
          </Grid.Row>
          {!this.state.hidden &&
            <Grid.Row>
              <Grid.Column width={16}>
                <Message
                  positive={this.state.positive}
                  hidden={this.state.hidden}
                  messageTitle={this.state.messageTitle}
                  messageBody={this.state.messageBody}
                />
              </Grid.Column>
            </Grid.Row>
          }
          <Grid.Row>
            <Grid.Column width={1} color='blue'>№</Grid.Column>
            <Grid.Column width={3} color='blue'>
              <div>Phone</div>
              <Input
                size='mini'
                placeholder='7 701 222 3344'
                name='phone'
                value={phone}
                onChange={this.onChange}
              />
              <div>WhatsApp phone</div>
              <Input
                size='mini'
                placeholder='7 701 222 3344'
                name='whatsapp_phone'
                value={whatsapp_phone}
                onChange={this.onChange}
              />
            </Grid.Column>
            <Grid.Column width={3} color='blue'>
              <div>Branches</div>
              <Input size='mini'
                placeholder='branch name'
                name='query'
                value={query}
                onChange={this.onChange}
              />
            </Grid.Column>
            <Grid.Column width={2} color='blue'>
              Role(s)
            </Grid.Column>
            <Grid.Column width={2} color='blue'>
              Cashier name
            </Grid.Column>
            <Grid.Column width={3} color='blue'>
              Email
            </Grid.Column>
            <Grid.Column width={2} color='blue'>
              Action
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <InfiniteScroll
          dataLength={businessUserList.length}
          next={this.fetchMoreData}
          hasMore={this.state.hasMore}
          loader={<h4>Loading...</h4>}
          height={height}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>No more data to load!</b>
            </p>
          }
        >
          <Grid celled style={{ marginTop: '0' }}>
            {businessUserList.map((user, index) => {
              return (
                <Grid.Row key={user.id} verticalAlign='middle' style={{ wordWrap: 'break-word' }}>
                  <Grid.Column width={1}>{index + 1}</Grid.Column>
                  <Grid.Column width={3} textAlign='center'>
                    {user.phone}
                  </Grid.Column>
                  <Grid.Column width={3}>
                    {user.branches.length > 0 &&
                      <React.Fragment >
                        {user.branches.map((branch) => (
                          <Label
                            key={branch.id}
                            style={{ cursor: 'pointer', marginBottom: 3 }}
                            onClick={() => this.props.history.push('/branch/' + branch.id)}
                          >
                            {branch.name}
                          </Label>
                        ))}
                      </React.Fragment>
                    }
                  </Grid.Column>
                  <Grid.Column width={2} textAlign='center'>
                    {user.branches.length > 0 &&
                      <React.Fragment>
                        {user.branches.map((branch) => { return branch.role }).join(", ")}
                      </React.Fragment>
                    }
                  </Grid.Column>
                  <Grid.Column width={2} >
                    {user.name}
                  </Grid.Column>
                  <Grid.Column width={3} >
                    {user.email}
                  </Grid.Column>
                  <Grid.Column width={2} className='tableActions'>
                    <Popup content='Edit' trigger={<Button color='orange' icon='edit' onClick={() => this.handleModalToggle(user.id)} />} />
                    <Popup content='Delete' trigger={<Button color='red' icon='trash' onClick={() => this.handelConfirmation(user.id)} />} />
                  </Grid.Column>
                </Grid.Row>
              )
            })}
          </Grid>
        </InfiniteScroll>
        {modalOpen &&
          <Modal
            centered={false} size='mini'
            open={modalOpen}
            onClose={this.handleModalToggle.bind(this, '')}
          >
            <Modal.Content>
              <Form size='mini' onSubmit={this.registerUpdateUser}>
                <Grid>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', fontSize: 18, fontWeight: 500 }}>
                    {currentUser.id ? 'Edit the user' : 'Add a user'}
                  </div>
                  {currentUser.branches.length > 0 &&
                    <Grid.Row width={16}>
                      {currentUser.branches.map((branch, index) => (
                        <Label key={index} style={{ marginTop: 3 }}>
                          {branch.name},
                          <span
                            style={{
                              color: branch.role === 'no role' ? 'red' : 'black',
                              marginLeft: 3
                            }}
                          >
                            {branch.role}
                          </span>
                          <Icon name='delete' onClick={this.deleteBranch.bind(this, branch.id)} />
                        </Label>
                      ))}
                    </Grid.Row>
                  }
                  {isDuplicated &&
                    <div style={{ color: 'red' }}>
                      The same branch with the same role is already added. Please choose another role or add another branch.
                    </div>
                  }
                  <Grid.Row width={16}>
                    <Grid.Column>
                      <Form.Input
                        fluid label='Phone number'
                        type='text' required
                        name='userPhone' placeholder='+77784445566'
                        value={currentUser.phone}
                        onChange={this.onChange}
                      />
                      <Form.Input
                        fluid label='Email address'
                        type='email' required
                        name='email' placeholder='example@gmail.com'
                        value={currentUser.email}
                        onChange={this.onChange}
                      />
                      <Form.Input
                        fluid label='Cashier name'
                        type='text'
                        name='name' placeholder='Type a name'
                        value={currentUser.name}
                        onChange={this.onChange}
                      />
                      <p style={{ fontSize: '14px', color: 'blue', textAlign: 'center' }}>
                        Add new branch to the list
                      </p>
                      <Dropdown
                        search placeholder='Select branch...'
                        closeOnChange clearable
                        options={branchListOptions} selection
                        name="branchId"
                        value={branchId}
                        onChange={this.onSelectChange} fluid
                        onSearchChange={this.onSearchChange}
                      />
                      {branchId &&
                        <Select
                          style={{ marginTop: '5px' }}
                          placeholder='Select role...' fluid
                          closeOnChange options={roleOptions}
                          name='role' value={role}
                          onChange={this.onSelectChange}
                        />
                      }
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row width={16}>
                    <Grid.Column>
                      <Button color='green' size='mini'
                        type='submit' floated='right'>
                        <Icon name={!currentUser.id ? 'add' : 'edit'} />
                        {!currentUser.id ? 'Register' : 'Update'}
                      </Button>
                      <Button color='orange' size='mini'
                        type='button' floated='right'
                        onClick={this.handleModalToggle.bind(this, '')}>
                        <Icon name='cancel' /> Cancel
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Modal.Content>
          </Modal>
        }
        {!modalOpen &&
          <Dimmer active={this.props.requestInProcess} inverted>
            <Loader inverted content='Loading' />
          </Dimmer>
        }
        {confirmOpen &&
          <Confirm
            open={confirmOpen}
            content='Вы действительно хотите удалить?'
            cancelButton='Отмена'
            confirmButton="Удалить"
            onCancel={() => this.handelConfirmation('')}
            onConfirm={() => this.deleteBusinessUser(currentUser.id)}
          />
        }
      </div>
    );
  }
}

BusinessUserList.propTypes = {
  getBusinessUserList: PropTypes.func,
  businessUserList: PropTypes.array,
  lastItemId: PropTypes.string,
  getBranchListForDropdown: PropTypes.func,
  branchList: PropTypes.array,
  requestInProcess: PropTypes.bool,
  registerUpdateBU: PropTypes.func,
  emptyBUserList: PropTypes.func,
  searchList: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBusinessUserList: (url) => dispatch(getBusinessUserList(url)),
    getBranchListForDropdown: (url) => dispatch(getBranchListForDropdown(url)),
    registerUpdateBU: (url, data, userId) => dispatch(registerUpdateBU(url, data, userId)),
    handleRemove: (url, branchId) => dispatch(handleRemove(url, branchId)),
    emptyBUserList: () => dispatch(emptyBUserList()),
    searchList: (url) => dispatch(searchList(url))
  };
};

const mapStateToProps = (state) => {
  return {
    businessUserList: state.businessUserList,
    requestInProcess: state.requestInProcess,
    branchList: state.branchList,
    lastItemId: state.lastItemId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessUserList);