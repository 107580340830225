import React from "react";
import { HexColorPicker } from "react-colorful";
import "./styles.css";

const ColorPicker = (props) => {
  const { color, setColor } = props;

  return (
    <div className="color-picker-body">
      <HexColorPicker color={color || "#000000"} onChange={setColor} />

      <div className="value">
        <button style={{ backgroundColor: color || "#000000", marginRight: "10px" }} />Текущий цвет: <strong>{color || "#000000"}</strong>
      </div>

      <div className="buttons">
        <button style={{ backgroundColor: "#ffffff" }} onClick={() => setColor("#ffffff")} />
        <button style={{ backgroundColor: "#c6ad23" }} onClick={() => setColor("#c6ad23")} />
        <button style={{ backgroundColor: "#556b2f" }} onClick={() => setColor("#556b2f")} />
        <button style={{ backgroundColor: "#207bd7" }} onClick={() => setColor("#207bd7")} />
        <button style={{ backgroundColor: "#fa2600" }} onClick={() => setColor("#fa2600")} />
        <button style={{ backgroundColor: "#14fa00" }} onClick={() => setColor("#14fa00")} />
        <button style={{ backgroundColor: "#000000" }} onClick={() => setColor("#000000")} />
      </div>
    </div>
  );
}

export default ColorPicker;