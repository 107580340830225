import React from 'react';
import { Grid, Button, Image, Input, Confirm, Select, Icon, Modal } from 'semantic-ui-react';
import ColorPicker from '../helpers/ColorPicker';

const codeTypes = [
  { key: 0, value: 'qr', text: 'qr' },
  { key: 1, value: 'barcode', text: 'barcode' }
]

const WalletData = (props) => {
  const {
    initialWalletState, wallet, banner, updateState, walletEditIsOn,
    modalOpen, toggleColorPickerModal, field, updateWalletSettings,
    removeBannerConfirmOpen, removeBanner, walletQr, generateWalletQr
  } = props;
  return (
    <Grid>
      <Grid.Row columns={6}>
        <Grid.Column style={{ display: 'flex', alignItems: "center" }}>
          <Button compact
            color={'green'}
            onClick={() => generateWalletQr()}
          >
            Получить Wallet QR
          </Button>
        </Grid.Column>
        <Grid.Column>
          <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <label>Цвет фона</label>
            <Input
              name='bg_color'
              value={wallet.bg_color}
              disabled={!walletEditIsOn}
              onChange={(e) => updateState('wallet', { ...wallet, bg_color: e.target.value })}
              icon={walletEditIsOn ? <Icon onClick={() => toggleColorPickerModal("bg_color", !modalOpen)} color='grey' name='edit' circular link /> : undefined}
            />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <label>Цвет шрифта</label>
            <Input
              name='text_color'
              value={wallet.text_color}
              disabled={!walletEditIsOn}
              onChange={(e) => updateState('wallet', { ...wallet, text_color: e.target.value })}
              icon={walletEditIsOn ? <Icon onClick={() => toggleColorPickerModal("text_color", !modalOpen)} color='grey' name='edit' circular link /> : undefined}
            />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <label>Тип штриха</label>
            <Select
              name="type"
              disabled={!walletEditIsOn}
              value={wallet.type}
              options={codeTypes}
              onChange={(_, data) => updateState('wallet', { ...wallet, type: data.value })}
              style={{ minWidth: "fit-content" }}
            />
          </div>
        </Grid.Column>
        <Grid.Column style={{ display: 'flex', alignItems: "center" }}>
          {walletEditIsOn ?
            <div style={{ display: "flex", gap: 1 }}>
              <Button compact
                color={'grey'}
                onClick={() => {
                  updateState('wallet', initialWalletState);
                  updateState('walletEditIsOn', false);
                }}
              >
                Отмена
              </Button>
              <Button compact
                color={'green'}
                onClick={() => updateWalletSettings()}
              >
                Сохранить
              </Button>
            </div> :
            <Button compact
              color={'blue'}
              onClick={() => updateState('walletEditIsOn', !walletEditIsOn)}
            >
              Изменить настройки
            </Button>
          }
        </Grid.Column>
        <Grid.Column style={{ display: 'flex', alignItems: "center" }}>
          {banner &&
            <Button compact
              color={'red'}
              onClick={() => updateState('removeBannerConfirmOpen', !removeBannerConfirmOpen)}
            >
              Удалить баннер
            </Button>
          }
        </Grid.Column>
      </Grid.Row>
      {walletQr &&
        <Grid.Row>
          <Grid.Column>
            <Image
              style={{ height: '250px', width: '250px' }}
              src={walletQr}
            />
          </Grid.Column>
        </Grid.Row>
      }
      {modalOpen &&
        <Modal
          centered={false}
          size='mini'
          open={modalOpen}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          closeIcon
          onClose={() => updateState('colorPickerModalOpen', false)}
        >
          <Modal.Content>
            <ColorPicker color={wallet[field]} setColor={(color) => updateState('wallet', { ...wallet, [field]: color })} />
          </Modal.Content>
        </Modal>
      }
      {removeBannerConfirmOpen &&
        <Confirm
          open={removeBannerConfirmOpen}
          content='Вы действительно хотите удалить баннер?'
          cancelButton='Нет'
          confirmButton="Да"
          onCancel={() => updateState('removeBannerConfirmOpen', !removeBannerConfirmOpen)}
          onConfirm={() => removeBanner()}
        />
      }
    </Grid>
  )
};
export default WalletData;